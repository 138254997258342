import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'sum'})
export class SumPipe implements PipeTransform {
    transform(items: any[], attr: string): any {
        return items.reduce((a, b) => a + b[attr], 0);
    }
}

@Pipe({name: 'sumNested'})
export class SumNestedPipe implements PipeTransform {
    transform(items: any[], attr: string, nestedList: any[]): any {
        return items.reduce((a, b) => a + this.getNestedObject(b[attr], nestedList), 0);
    }

    getNestedObject(nestedObj, pathArr): any {
        return pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);
    }
}
