import {NgModule} from '@angular/core';

import {KeysPipe} from '@fuse/pipes/keys.pipe';
import {GetByIdPipe} from '@fuse/pipes/getById.pipe';
import {HtmlToPlaintextPipe} from '@fuse/pipes/htmlToPlaintext.pipe';
import {FilterPipe} from '@fuse/pipes/filter.pipe';
import {CamelCaseToDashPipe} from '@fuse/pipes/camelCaseToDash.pipe';
import {SumNestedPipe, SumPipe} from '@fuse/pipes/sum.pipe';
import {RoundTotalPipe} from '@fuse/pipes/roundTotal.pipe';
import { RoleEnablePipe, RoleShowPipe } from '@fuse/pipes/role.pipe';

@NgModule({
    declarations: [KeysPipe, GetByIdPipe, HtmlToPlaintextPipe, FilterPipe, CamelCaseToDashPipe, SumPipe, SumNestedPipe, RoundTotalPipe, RoleShowPipe, RoleEnablePipe],
    imports: [],
    exports: [KeysPipe, GetByIdPipe, HtmlToPlaintextPipe, FilterPipe, CamelCaseToDashPipe, SumPipe, SumNestedPipe, RoundTotalPipe, RoleShowPipe, RoleEnablePipe]
})
export class FusePipesModule {}
