import {Pipe, PipeTransform} from '@angular/core';
import { Store } from '@ngxs/store';
import { ROLE_SETTINGS } from 'app/app.constants';
import { RoleService } from 'app/blocks/service/api/role.service';
import { Observable } from 'rxjs';

@Pipe({name: 'roleShow'})
export class RoleShowPipe implements PipeTransform {
    constructor(private roleService: RoleService) {}
    transform(key: string): Observable<boolean> {
        return this.roleService.subscribeToShowSetting(key);
    }
}

@Pipe({name: 'roleEnable'})
export class RoleEnablePipe implements PipeTransform {
    constructor(private roleService: RoleService) {}
    transform(key: string): Observable<boolean> {
        return this.roleService.subscribeToEnableSetting(key);
    }
}
